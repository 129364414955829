import React, { useState,useContext } from 'react'
import { useNavigate } from 'react-router-dom';

const Navigation = () => {

  const [sidebar,setSidebar]=useState(false);
  const navigate = useNavigate();


  return (
    <>
        <div className='w-screen h-20 flex justify-between max-[800px]:hidden'>
            <div className='w-[calc(200px)] h-full bg-white grid place-items-center' >
                <span className='text-2xl font-mainfont'>ZADES</span>
            </div>
            <div className='w-auto h-full flex items-center text-xs gap-10 p-3'>
                <a href="https://www.zades.in/">NEW ARRIVALS</a>
                <a href="">NEW ARRIVALS</a>
                <a href="">NEW ARRIVALS</a>
            </div>
            <div className='w-[calc(200px)] h-full bg-white flex'>
                <div className='flex items-center justify-center w-1/2 h-full'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000" strokeWidth="2"  strokeLinecap="round" strokeLinejoin="round"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg>
                </div>
                <div className='flex items-center justify-center w-1/2 h-full' onClick={()=>{setSidebar(true)}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                </div>
            </div>
        </div>
        <div className='max-[800px] w-screen h-20 flex justify-between min-[800px]:hidden'>
            <div className='w-[100px] px-2 h-full flex items-center justify-center'>
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000"><path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z"/></svg>
            </div>
            <div className='w-auto h-full bg-white grid place-items-center'>
                <span className='text-2xl font-mainfont'>ZADES</span>
            </div>
            <div className='w-[100px] px-2 h-full flex items-center justify-center'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg>
            </div>
        </div>
        <div className='w-screen h-10 bg-black flex items-center justify-center gap '>
            <a href="/">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fffafa" viewBox="0 0 256 256"><path d="M218.83,103.77l-80-75.48a1.14,1.14,0,0,1-.11-.11,16,16,0,0,0-21.53,0l-.11.11L37.17,103.77A16,16,0,0,0,32,115.55V208a16,16,0,0,0,16,16H96a16,16,0,0,0,16-16V160h32v48a16,16,0,0,0,16,16h48a16,16,0,0,0,16-16V115.55A16,16,0,0,0,218.83,103.77ZM208,208H160V160a16,16,0,0,0-16-16H112a16,16,0,0,0-16,16v48H48V115.55l.11-.1L128,40l79.9,75.43.11.1Z"></path></svg>
            </a>
            <svg fill="#fffafa" xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20   "><path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z"></path></svg>
            <span className='text-white text-xs font-secondaryfont font-semibold'>CART</span>
        </div>
    </>

  )
}

export default Navigation