import React from 'react'
import apicarthandler from './cart.js';
import { useEffect, useState,useContext, useRef } from 'react';
import Footer from './components/Footer.tsx';
import { useSelector,useDispatch } from 'react-redux';
import { login,logout } from './microservices/AuthReducers.js';
import { useNavigate } from 'react-router-dom';

const api = new apicarthandler();

const Home = () => {

    const [productdata, setProductdata] = useState([]);
    const [sidebar,setSidebar]=useState(false);
    const productLoaded = useRef(false);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();



    

    useEffect(() => {
        if (productLoaded.current) return;
        api.getmainproducts().then((result)=>{
            new Promise((resolve)=>{
                if(result.auth)
                {
                    dispatch(login({user:result.username}));
                }
                setProductdata(result.products[0]);
                resolve(true);
            }).then((response)=>{
                if(response){
                    productLoaded.current=true;
                }
            })
        })
    }, []);
    return (
        <>
            <div className="h-10 w-full flex items-center justify-center bg-black text-white text-xs tracking-widest relative">
                <span className='text-[10px] font-normal'>CREATIVE CLOTHING STORE</span>
            </div>
            <div className='absolute top-[40px] w-screen h-20 flex justify-between max-[800px]:hidden'>
                <div className='w-[calc(200px)] h-full bg-transparent grid place-items-center' >
                    <span className='text-2xl font-mainfont text-white'>ZADES</span>
                </div>
                <div className='w-auto h-full flex items-center text-xs gap-10 p-3'>
                    <a href="https://www.zades.in/" className='text-white'>NEW ARRIVALS</a>
                    <a href="" className='text-white'>NEW ARRIVALS</a>
                    <a href="" className='text-white'>NEW ARRIVALS</a>
                </div>
                <div className='w-[calc(200px)] h-full  flex'>
                    <div className='flex items-center cursor-pointer justify-center w-1/2 h-full' onClick={()=>{navigate("/cart")}}>
                        <svg  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" strokeWidth="2"  strokeLinecap="round" strokeLinejoin="round"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg>
                    </div>
                    <div className='flex items-center justify-center w-1/2 h-full cursor-pointer' onClick={()=>{setSidebar(!sidebar);}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                    </div>
                </div>
            </div>


            <div className='max-[800px]  absolute top-[40px] w-screen h-20 flex justify-between min-[800px]:hidden'>
                <div className='w-[100px] px-2 h-full flex items-center justify-center' onClick={()=>{setSidebar(!sidebar);}}>
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#fff"><path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z"/></svg>
                </div>
                <div className='w-auto h-full grid place-items-center'>
                    <span className='text-2xl font-mainfont text-[#fff]'>ZADES</span>
                </div>
                <div className='w-[100px] px-2 h-full flex items-center justify-center' onClick={()=>{window.location.href="/cart"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg>
                </div>
            </div>

            <div className={`w-[300px] border-[0.8px] border-[#ffffff18] h-fit min-h-[40px] bg-[#ffffff15] backdrop-blur-[101px] rounded-lg absolute top-[120px] right-5 p-3 ${sidebar?"flex":"hidden"} flex-col shadow-[rgba(17,17,26,0.05)_0px_1px_0px,rgba(17,17,26,0.1)_0px_0px_8px] max-[800px]:left-5 min-[800px]:left-auto right-5`}>
                <div className='w-full h-fit flex items-center'>
                    <span className='text-white'>Hi, {isAuthenticated ? user:"User"}</span>
                </div>

                <div className='w-full h-[1.5px] bg-[#ffffff18] rounded-lg backdrop-blur-[101px] mt-2 mb-2'></div>
                {isAuthenticated?
                <>
                    <div className='w-full h-[32px] rounded-lg p-2 flex items-center cursor-pointer hover:backdrop-blur-[101px] my-1' onClick={()=>{
                        navigate('/orders');
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="#fff" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                        <span className='ml-3 tracking-widest text-[10px] font-semibold text-[#fff]'>ORDERS</span>
                    </div>
                    <div className='w-full h-[32px] rounded-lg p-2 flex items-center cursor-pointer hover:backdrop-blur-[101px] my-1' onClick={()=>{
                        dispatch(logout());
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="#fff" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                        <span className='ml-3 tracking-widest text-[10px] font-semibold text-[#fff]'>LOGOUT</span>
                    </div>
                </>:
                <>
                    <a href='/login' className='w-full h-[32px] rounded-lg p-2 flex items-center cursor-pointer hover:backdrop-blur-[101px]'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="#fff" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path><polyline points="10 17 15 12 10 7"></polyline><line x1="15" y1="12" x2="3" y2="12"></line></svg>
                        <span className='ml-3 tracking-widest text-[10px] font-semibold text-[#fff]'>LOGIN</span>
                    </a>                
                </>
                }

            </div>


            <div className='w-screen h-[calc(100vh-40px)] bg-mainbg bg-no-repeat bg-center bg-cover flex items-center justify-center'>
                <a className='w-[300px] h-[50px] rounded-full  bg-[#ffffff15] backdrop-blur-[101px]  flex items-center justify-center cursor-pointer' href='/allproducts'>
                    <span className='text-white font-mainfont'>SHOP NOW</span>
                </a>
            </div>

            <div className="w-[calc(100%)] h-[50px] flex items-center pt-2.5 px-[25px] py-0;">
                <div className="w-fit h-full text-xs font-medium flex items-center tracking-[0.6px]">
                    <span className="eventname">EXCLUSIVE</span>
                </div>
                <div className=" w-[90px] h-[30px] flex items-center justify-center text-xs font-medium text-black bg-neutral-50 border ml-auto rounded-sm border-solid border-black">
                    <span>SHOW MORE</span>
                </div>
            </div>
            <div className="maingrid">
                {
                    productdata.map((product,index)=>{
                        return(
                        <>
                            <a className=" w-[calc(100%)] h-fit flex items-center justify-center flex-col cursor-pointer overflow-hidden" href={`/product/${product.productid}`} >
                                <div className="w-[calc(100%-10px)] aspect-[3/4] overflow-hidden">
                                    <div className="w-full h-full bg-cover bg-center bg-no-repeat transition-[0.6s] duration-[all] relative overflow-hidden">
                                        <img className='absolute ease-linear duration-500 w-full h-full object-cover hover:scale-105' src={"https://firebasestorage.googleapis.com/v0/b/zades-c1877.appspot.com/o/"+product.productid+"_0"+"?alt=media&token=3cbb4e36-63ac-4256-a8c3-0020016c0d89"} alt="" />
                                    </div>
                                </div>
                                <div className="w-[calc(100%_-_10px)] h-[60px] px-[5px] py-0">
                                    <div className="w-full h-5 flex items-center text-xs font-semibold pt-[5px]">
                                        <span>{product.name}</span>
                                    </div>
                                    <div className="w-full h-5 flex items-center text-xs font-bold text-[#0000008b]">
                                        <span>RS.{product.price}</span>
                                    </div>
                                </div>
                            </a>
                        </>
                        );
                    })
                }
            </div>

                
            <Footer />
    </>
    )
}

export default Home