import React from 'react'
import apicarthandler from './cart.js';
import Navigation from './components/Navigation.tsx'
import { useEffect, useState,useContext, useRef } from 'react';
import Footer from './components/Footer.tsx';


const api = new apicarthandler();

const AllProducts = () => {    
    const [productdata, setProductdata] = useState([]);
    const productLoaded = useRef(false);

    useEffect(() => {
        if (productLoaded.current) return;
        api.getmainproducts().then((result)=>{
            new Promise((resolve)=>{
                setProductdata(result.products[0]);
                resolve(true);
            }).then((response)=>{
                if(response){
                    productLoaded.current=true;
                }
            })
        })
    }, []);

  return (
    <>
        <Navigation />

        <div className="pageinfo_cont w-full h-[100px] flex px-0 py-2.5">
            <div className="pagespan w-[200px] flex items-start">
                <div className="w-full h-fit flex mt-2.5">
                    <div className="shopbyspan flex flex-col h-fit ml-1.5" data-aos="fade-up" data-aos-duration="1000" data-aot-easing="ease" data-aos-offset="0">
                        <span className='font-semibold tracking-[0.3px] text-[small] text-[#03071ebb]'>SHOP BY</span>
                        <span className='text-[50px]'>EXCLUSIVE</span>
                    </div>   
                </div>
            </div>
            {/* <div className="sortfiltercont h-full w-[30%] flex items-center justify-evenly ml-auto">
                <div className="filtercont w-[200px] h-[45px] bg-[#ececec] flex items-center justify-center text-[medium] font-semibold tracking-[1px] cursor-pointer rounded-lg" onclick="filtershow(this)" data-sort="open">
                    <svg className="mr-[5px]" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon></svg>
                    <span>filter</span>
                </div>
                <div className="sortcont w-[200px] h-[45px] bg-[#ececec] flex items-center justify-center text-[medium] font-semibold tracking-[1px] cursor-pointer rounded-lg" onclick="sortshow(this)" data-sort="open">
                    <svg className='mr-[5px]' xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#000000" viewBox="0 0 256 256"><path d="M128,128a8,8,0,0,1-8,8H48a8,8,0,0,1,0-16h72A8,8,0,0,1,128,128ZM48,72H184a8,8,0,0,0,0-16H48a8,8,0,0,0,0,16Zm56,112H48a8,8,0,0,0,0,16h56a8,8,0,0,0,0-16Zm125.66-21.66a8,8,0,0,0-11.32,0L192,188.69V112a8,8,0,0,0-16,0v76.69l-26.34-26.35a8,8,0,0,0-11.32,11.32l40,40a8,8,0,0,0,11.32,0l40-40A8,8,0,0,0,229.66,162.34Z"></path></svg>
                    <span>sort</span>
                </div>
            </div> */}
        </div>

        <div className="w-full px-0 py-[5px]">
            <div className="h-full text-[medium] font-bold text-[#000000b4] px-2.5 py-0">
                products found
            </div>

        </div>


        <div className="w-full">
            <div className="maingrid">
                {/* style="text-decoration: none;color:#000;" */}
                {productdata.map((product,index)=>{
                    let namesplit=product.name;
                    namesplit=namesplit.replaceAll(" ","-",);
                    return(
                    <a key={index} href={`/product/${namesplit}-${product.productid}`} className="productgrid  w-[calc(100%)] h-fit flex items-center justify-center flex-col cursor-pointer overflow-hidden;" >
                    <div className="w-[calc(100%_-_10px)] aspect-[3/4] overflow-hidden">
                        <div className="w-full h-full bg-cover bg-center bg-no-repeat transition-[0.6s] duration-[all] relative">
                            <img className='absolute ease-linear duration-500 w-full h-full object-cover hover:scale-105' src={"https://firebasestorage.googleapis.com/v0/b/zades-c1877.appspot.com/o/"+product.productid+"_0"+"?alt=media&token=3cbb4e36-63ac-4256-a8c3-0020016c0d89"} alt="" />
                        </div>
                    </div>
                    <div className="w-[calc(100%_-_10px)] h-[60px] px-[5px] py-0" >
                        <div className="w-full h-5 flex items-center text-xs font-semibold pt-[5px]">
                            <span>{product.name}</span>
                        </div>
                        <div className="w-full h-5 flex items-center text-xs font-bold text-[#0000008b]">
                            <span>RS. {product.price}</span>
                        </div>
                    </div>
                </a>);
                })}
            </div>
        </div>
        <Footer />
    </>
  );
}
export default AllProducts