import React, { useEffect, useState } from 'react'
import apicarthandler from '../cart.js'
import { json } from 'express';

const api = new apicarthandler();

const AddAddress = (props) => {
  
  const [name,setName]=useState('');
  const [number,setNumber]=useState('');
  const [city,setCity]=useState('');
  const [pincode,setPincode]=useState('');
  const [address1,setAddress1]=useState('');
  const [address2,setAddress2]=useState('');
  const [address,setAddress]=useState('');
  const [isdefault,setIsdefault]=useState(false);

  useEffect(()=>{
    searchpincode();
  },[pincode])



  const searchpincode = () =>{
    let debouncepincode = setTimeout(()=>{
      sendapipincode(pincode).then((data)=>{
        setCity(data);
      })
    },1000)
    return ()=>{clearInterval(debouncepincode);
    }
  }

  const sendapipincode =  async (pincode)=>{
    let response = await fetch("https://api.postalpincode.in/pincode/"+pincode)
    let data = await response.json();
    if(data[0]["Status"]==="Success")
    {
      for(let i=0;i<=data[0]['PostOffice'].length;i++)
        {
          if(data[0]['PostOffice'][i]["BranchType"]==="Head Post Office")
          {
            return(data[0]['PostOffice'][i]["Name"]);
          }
        }
    }

    return "...";

  }


  const addresshandler = ()=>{
    if(pincode==''||city==''||number==''||address1==''||address2==''||address=='')
    {
      alert("empty");
      return false;
    }
    else{
      return true;
    }
  }


  return (
    <>
      <div className="fixed w-full h-full flex top-0 left-0  items-center justify-center bg-slate-200 z-50">
        <div className="w-[400px] h-[90vh] p-5 rounded-3xl bg-white flex flex-col max-[400px]:w-[90vw] h-[80vh]">
          <div className="flex items-center w-full h-auto text-[14px] font-semibold justify-between">
            <span className='font-semibold text-md'>Add New Address</span>
            <svg onClick={()=>props.closefunc(false)} xmlns="http://www.w3.org/2000/svg" width="20"
              height="20" viewBox="0 0 24 24" fill="none"
              stroke="currentColor" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round"
              className="p-1 rounded cursor-pointer">
              <line x1="19" y1="12" x2="5" y2="12"></line>
              <polyline points="12 19 5 12 12 5"></polyline>
            </svg>
          </div>
          <div className='flex flex-col w-full h-auto mt-[30px] gap-5'>
            <div className='flex flex-col w-full h-auto gap-1'>
              <span className='text-[10px] font-semibold'>Name</span>
              <input onChange={(e)=>{setName(e.target.value)}} type="text" className='w-full h-[30px] text-xs rounded border-[#ccc] border p-1' placeholder='enter name' />
            </div>
            <div className='flex flex-col w-full h-auto gap-1'>
              <span className='text-[10px] font-semibold'>Mobile Number</span>
              <input onChange={(e)=>{setNumber(e.target.value)}}  type="number" className='w-full h-[30px] text-xs rounded border-[#ccc] border p-1' placeholder='mobile number' />
            </div>
            <div className='flex w-full h-auto gap-1'>
              <div className='flex flex-col w-1/2 h-auto gap-1'>
                <span className='text-[10px] font-semibold'>Picode</span>
                <input onChange={(e)=>{setPincode(e.target.value)}}  type="number" className='w-full h-[30px] text-xs rounded border-[#ccc] border p-1' placeholder='pincode' />
              </div>
              <div className='flex flex-col w-1/2 h-auto gap-1'>
                <span className='text-[10px] font-semibold'>City</span>
                <input id='city' onChange={(e)=>{setCity(e.target.value)}}  type="text" className='w-full h-[30px] text-xs rounded border-[#ccc] border p-1' value={city} placeholder='city' />
            </div>
            </div>
            <div className='flex flex-col w-full h-auto gap-1'>
              <span className='text-[10px] font-semibold'>Address 1</span>
              <input onChange={(e)=>{setAddress1(e.target.value)}}  type="text" className='w-full h-[30px] text-xs rounded border-[#ccc] border p-1' placeholder='enter d.no' />
            </div>
            <div className='flex flex-col w-full h-auto gap-1'>
              <span className='text-[10px] font-semibold'>Adddress 2</span>
              <input onChange={(e)=>{setAddress2(e.target.value)}}  type="text" className='w-full h-[30px] text-xs rounded border-[#ccc] border p-1' placeholder='enter street name' />
            </div>
            <div className='flex flex-col w-full h-auto gap-1'>
              <span className='text-[10px] font-semibold'>Address</span>
              <textarea onChange={(e)=>{setAddress(e.target.value)}}  name="" className='w-full h-[70px] text-xs rounded border-[#ccc] border p-1' id=""></textarea>
            </div>
            <label htmlFor="default" className='flex h-5 w-full gap-1 items-center'>
              <input onChange={(e)=>{
                if(e.target.checked)
                {
                  setIsdefault(true);
                }
                else{
                  setIsdefault(false);
                }
              }} type="checkbox" name="default" id="" />
              <span className='text-[10px]'>set address as default</span>
            </label>
          </div>
          <div className='w-full h-[40px] flex items-center justify-center bg-black rounded-full mt-auto cursor-pointer' onClick={(e)=>{
              if(addresshandler())
              {
                document.getElementsByClassName('addloader')[0].classList.replace('hidden','flex');
                document.getElementsByClassName('addspan')[0].classList.replace('flex','hidden');

                api.adduseraddress(city,number,pincode,address1,address2,address,isdefault,name).then(()=>{
                  props.closefunc(false);
                  props.loadaddressfunc();
                  document.getElementsByClassName('addloader')[0].classList.replace('flex','hidden');
                  document.getElementsByClassName('addspan')[0].classList.replace('hidden','flex');
                })
              }

              else{
                alert("ohh")
              }
            }}>
              <svg className='addloader animate-spin hidden' xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="12" y1="2" x2="12" y2="6"></line><line x1="12" y1="18" x2="12" y2="22"></line><line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line><line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line><line x1="2" y1="12" x2="6" y2="12"></line><line x1="18" y1="12" x2="22" y2="12"></line><line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line><line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line></svg>
              <span className='addspan text-[#fff] font-normal text-[10px]' >Add Address</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddAddress