import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import apicarthandler from '../cart.js';
import AddAddress from './AddAddress.tsx';

const api=new apicarthandler();

export const Address = (props) => {
    const [addressview,setAddressview]=useState(false);
    const [selected,setSelected]=useState(null);

    const getaddress= async ()=>{
        api.getuseraddress().then((response)=>{
            props.setAddress(()=>{
                response['address'].forEach((element,index) => {
                    if(element.ismain)
                    {
                        setSelected(index);
                        props.setSelected(index);
                    }
                });
                return response['address']
            });
            props.addressloaded.current=true;
        });
    }





    useEffect(()=>{
        if(!props.addressloaded.current)
        {
            getaddress();
        }
    },[])


  return (
    <>
    {addressview && <AddAddress closefunc={setAddressview} loadaddressfunc={getaddress} /> }
    
    <div className='adresscont w-1/2 h-auto min-h-full flex flex-col gap-6 z-40 max-[400px]:w-full'>
        <div onClick={()=>setAddressview(true)} className='addadress w-[200px] h-10 bg-black rounded-md p-2 flex items-center gap-2 justify-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg><span className='text-[10px] font-semibold text-white'>ADD ADDRESS</span></div>
        <div className='w-full h-auto flex flex-col gap-3'>
            {props.address.map((addresss,index)=>{
                const selectedAddress = {
                    border:selected==index?"#000 1px solid":"#0000006a 1px solid"
                };


                const cardselected=(index)=>{
                    setSelected(index);
                    props.setSelected(index);
                }



                return(
                    <label className='flex gap-1 items-center'>
                        <input type="radio" name="address" className='cursor-pointer hidden' onChange={()=>cardselected(index)} /> 
                            <div className='w-[400px] h-[80px] rounded-lg flex flex-col bg-gray-100 p-2' style={selectedAddress}>
                            <div className='w-full h-[40px] flex '>
                                <div className='addsvg h-full aspect-square bg-black rounded-md grid place-items-center'>
                                    <svg width={20} height={20}  fill="none" strokeWidth={1.5} stroke="#fff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                                    </svg>
                                </div>
                                <div className='flex-auto h-full  flex flex-col gap ps-2 justify-center'>
                                    <span className='text-[12px] font-bold'>{addresss["city"]}</span>
                                    <span className='text-[10px] text-[#00000070] font-semibold'>{addresss["address"]}</span>
                                </div>
                            </div>
                            <div className='w-full flex-auto  flex items-center gap-1'>
                                <span className='text-[10px] text-[#00000070] font-semibold ml-auto'>more details</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#00000070" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="6 9 12 15 18 9"></polyline></svg>
                            </div>
                        </div>
                    </label>
                );
            })}

        </div>
    </div>
    </>
  );
}


export default Address;