import React from 'react';
import { UseDispatch,useSelector } from 'react-redux';
import { login,logout } from '../microservices/AuthReducers';

const PaymentCard = (props) => {
  return (
    <>
    <div className='cartdetails min-[804px]: w-1/2  h-full  flex justify-end max-[804px]:hidden '>
        <div className='w-[90%] h-[200px] bg-white p-3 border rounded-xl border-gray-300 flex flex-col gap-2'>
          <div className='w-auto h-auto flex'>
              <div className='w-auto h-auto gap flex flex-col'>
                <span className='text-[12px] font-semibold text-[#000000]'>AMOUNT</span>
                <span className='text-[8px] font-semibold text-[#00000081]'>TOTAL AMOUNT OF CART</span>
              </div>

              <div className='w-auto h-full ml-auto flex items-center justify-center'>
                <span className='text-[12px] font-semibold'>RS. {props.total}</span>
              </div>
          </div>
          <div className='h-9 w-3/5 bg-[#eaeaea]  p-1 flex items-center rounded-lg'>
              <input type="text" className='w-4/5 h-full bg-transparent border-none outline-none text-[10px] p-1' placeholder='ENTER COUPUN' />
              <div className='w-1/5 h-full bg-gray-50 flex items-center justify-center rounded'><span className='text-[10px] text-[#0000007a]'>APPLY</span>
              </div>
          </div>
          <div className='w-full h-10 mt-auto bg-black rounded-md flex items-center justify-center cursor-pointer' onClick={()=>{props.statusfunc()}}><span className='text-white text-[10px] font-semibold'>NEXT {props.status}</span></div>
          <div className='w-full h-10 mt-auto bg-white border border-gray-300 rounded-md flex items-center justify-center cursor-pointer' onClick={()=>{props.statusrevfunc()}}><span className='text-black text-[10px] font-semibold'>BACK</span></div>
          </div>
  </div>
  <div className='w-screen z-40 h-[70px] bg-white fixed left-0 bottom-0 px-5 py-2 flex border-t-[1px] border-[#eaeaea] justify-between max-[400px]:px-3 min-[804px]:hidden'>
    <div className='w-auto h-full flex flex-col gap-[1px] justify-center'>
    <span className='text-[7px] font-semibold text-[#0000009f]'>TOTAL AMOUNT</span>
    <span className='text-[16px] font-semibold text-[#000]'>RS {props.total}</span>
    </div>
    <div className='w-1/2 h-full rounded-lg bg-black flex items-center justify-center' onClick={()=>{props.statusfunc()}}>
      <span className='text-[12px] font-semibold text-[#fff]'>{props.status}</span>
    </div>
  </div>
  </>
  );
}

export default PaymentCard;