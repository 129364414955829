import React from 'react'

const Footer = () => {
  return (
    <>
    <footer>
        <div className="footercont">
            <div className="aboutcont">
                <div className="aboutmain">
                    <div className="brandcont">ZADES</div>
                    <div className="brandinsp">
                        <p>
                        </p>
                    </div>
                </div>
            </div>
            <div className="shoopinglinks">
                <div className="shoopinglinkscont">
                    <div className="shoplinkspan">
                        <span>SHOP ALL</span>
                    </div>
                    <div className="shoplink">
                        <a href="">Exclusive</a>
                        <a href="">New arrival</a>
                        <a href="">T shirts</a>
                    </div>
                </div>
            </div>
            <div className="informationcont">
                <div className="informationmain">
                    <div className="infospan">
                        <span>ABOUT US</span>
                    </div>
                    <div className="infolink">
                        <a href="">About us</a>
                        <a href="">Contact us</a>
                        <a href="">Terms and Conditions</a>
                        <a href="">Privacy Policy</a>
                    </div>
                </div>
            </div>
            <div className="manageordercont">
                <div className="managecont">
                    <div className="managespan">
                        MANAGE ORDERS
                    </div>
                    <div className="managelink">
                        <a href="">Track orders</a>
                        <a href="">Shipping & delivery</a>
                    </div>
                </div>

            </div>
            <div className="followus">
                <div className="followcont">
                    <div className="followspan">
                        <span>FOLLOW US</span>
                    </div>
                    <div className="followsvg">
                        <a href="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-instagram"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg>
                        </a>
                        <a href="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-facebook"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg>
                        </a>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#ffffff96" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-twitter"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg>
                    </div>
                </div>
            </div>
        </div>
        <div className="copyrightcont">
            <div className="copyright">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#ffffff96" viewBox="0 0 256 256"><path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216ZM96,128a32,32,0,0,0,57.6,19.2,8,8,0,0,1,12.8,9.61,48,48,0,1,1,0-57.62,8,8,0,0,1-12.8,9.61A32,32,0,0,0,96,128Z"></path></svg>
                <span className='ml-[5px]'>ALL RIGHTS RESERVED</span>
            </div>
            <div className="termsconditions">
                <span>TERMS AND CONDITIONS</span>
            </div>
        </div>
    </footer>
    </>
  )
}

export default Footer