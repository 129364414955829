import React from 'react'
import { useState,useContext } from 'react';
import { CartContext } from '../CartPage.js';
import apicarthandler from '../cart';
import Loader from './Loader.tsx';
import { AuthContext } from '../microservices/AuthProvider.js';

const api=new apicarthandler();

export const CartCard = (props) => {

  const [qty, setQty] = useState(props.cartdata.unit);
  const [id,setId] = useState(props.cartdata.id);
  const [progress,setProgress] = useState(false);
  const [message,setMessage] = useState('')
  const cartloadfunc=useContext(CartContext);

  return (
    <>
    {progress && 
      <Loader specific={true} message={message} ></Loader>
    }

    <div className='card w-full border border-gray-300 h-40 p-3 bg-white rounded-xl flex relative z-30'>
      <div className='absolute top-[10px] right-[10px] cursor-pointer' onClick={(e)=>{
        e.preventDefault();
        setMessage('Deleteing Product');
        setProgress(true);
        props.contentloaded.current=false;
        if ("")
        {
          api.deleteproduct(id,props.productdata.productid,props.cartdata["size"]).then(()=>{
            cartloadfunc().then(()=>{
              setProgress(false);
            })
          })
        }
        else{
          api.deleteproduct(id,props.productdata.productid,props.cartdata["size"]).then(()=>{
            cartloadfunc().then(()=>{
              setProgress(false);
            })
          })
        }
        }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" viewBox="0 0 256 256"><path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path></svg>
      </div>
      <div className='h-full aspect-[3/4] rounded-md bg-[#eaeaea] relative overflow-hidden'>
        <img className='absolute w-full h-full object-cover' src={"https://firebasestorage.googleapis.com/v0/b/zades-c1877.appspot.com/o/"+props.productdata.productid+"_0"+"?alt=media&token=3cbb4e36-63ac-4256-a8c3-0020016c0d89"} alt="" />
      </div>
      <div className='h-full flex-aut pl-2 flex flex-col gap-2'>
        <div className='w-full h-auto flex flex-col gap justify-center'>
          <span className='font-bold text-[10px] text-[#000000d1]'>{props.productdata.name}</span>
          <span className='font-semibold text-[10px] text-[#00000078]'>{props.productdata.discription}</span>

        </div>
        <div className='w-full h-auto flex flex-col gap justify-center'>
          <span className='font-bold text-[10px] text-[#00000096] '>RS {props.productdata.price}</span>
        </div>
        <div className='w-full h-auto flex gap-1 items-center'>
         <span className='font-bold text-[10px] text-[#00000096]'>{props.cartdata['size']}</span>
        </div>

        <div className='w-auto h-auto flex items-center gap-2'>
          <div className='flex w-[60px] h-7 items-center rounded-lg border-2 border-gray-200'>
            <div className='qtyminus w-[33.33%] h-full  flex items-center justify-center' onClick={(e)=>
              {
                e.preventDefault();
                setMessage('Decreasing Quantity');
                setProgress(true);
                props.contentloaded.current=false;
                api.changeproductqty(id,props.productdata.productid,props.cartdata["size"],-1).then(()=>{
                  cartloadfunc().then(()=>{
                    setQty((prevQty)=>prevQty-1);
                    setProgress(false);
                  })
                });
              }
            }><svg xmlns="http://www.w3.org/2000/svg" className='hover:stroke-black cursor-pointer' width="12" height="12" viewBox="0 0 24 24" fill="#eaeaea" stroke="#ccc" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="5" y1="12" x2="19" y2="12"></line></svg></div>
            <div className='qtyshow w-[33.33%] h-full   flex items-center justify-center'><span className='text-[10px]'>{qty}</span></div>
            <div className='qtyadd w-[33.33%] h-full   flex items-center justify-center ' onClick={(e)=>{
              e.preventDefault();
              setMessage('Adding Quantity');
              setProgress(true);
              props.contentloaded.current=false;
              api.changeproductqty(id,props.productdata.productid,props.cartdata["size"],1).then(()=>{
                cartloadfunc().then(()=>{
                  setQty((prevQty)=>prevQty+1);
                  setProgress(false);
                })
              });
              }}><svg xmlns="http://www.w3.org/2000/svg" className='hover:stroke-black cursor-pointer' width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="#cccccc" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg></div>
          </div>
        </div>

        <div className='flex flex-auto w-auto  items-center gap-1'>
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24" fill="none" stroke="#00000081" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg>
            <span className='text-[8px] font-medium text-[#00000081]'>MADE WITH LOVE</span>
        </div>
      </div>
    </div>
    </>
  );
}

export default CartCard;
