import { createSlice } from '@reduxjs/toolkit';

const logoutuser = async () =>{
  const getcsrf = ()=>{
    let cookieValue = null;
    const name = 'csrftoken';
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
  }

  let response = await fetch("/apilogout",{
    method:'POST',  
    headers:
    {
        'Content-Type':'application/json',
        'X-CSRFToken': getcsrf(),
    },
  });
  let data= await response.json();
}

const authSlice = createSlice({

  name: 'auth',

  initialState: {
    isAuthenticated: false,
    user:null,
  },

  reducers: {
    login: (state,action) => {
      state.isAuthenticated = true;
      state.user=action.payload.user;
    },

    logout: (state) => {
      logoutuser();
      state.isAuthenticated = false;
      state.user=null;
    },
  },
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;