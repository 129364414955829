import React from 'react';
import './index.css';
import Login from './Login';
import Product from './Product';
import Home from './Home';
import Orders from './Orders';
import AllProducts from './AllProducts'
import { BrowserRouter as Router, Route,Routes } from 'react-router-dom';
import CartPage from './CartPage.js';


function App() {


  return (
    <>
    <Router>
        <Routes>
                <Route exact path='/' element={
                        <Home/>
                }/>
                <Route path='/orders' element={
                  <Orders />
                } />

                <Route path='/cart' element={<CartPage />} />
                <Route path='/allproducts' element={<AllProducts />} />
                {/* <Route path='/product/:productId' element={<Product />} /> */}
                {/* <Route path='/login' element={<Login />} /> */}
        </Routes>
    </Router>

    </>
  );
}

export default App;
