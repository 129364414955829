import React, { useEffect, useState } from 'react'
import Footer from './components/Footer.tsx';
import { useNavigate } from 'react-router-dom';
const Orders = () => {
  const [isExpanded,setIsexpanded]=useState(false);
  const [loading,setLoading]=useState(true);
  const deliveryStatus=["Shipping","On the way","Deliverd"];
  const [shippingorders,setShippingorders]=useState([]);
  const [shippingproducts,setShippingproducts]=useState([]);
  const navigate = useNavigate();
  const [onwayorders,setOnwayorders]=useState([]);

  const getcsrf = ()=>{
    let cookieValue = null;
    const name = 'csrftoken';
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

  const cardOpen = (e)=>{
    setIsexpanded(!isExpanded);
  }

  useEffect(()=>{
    getuserorders().then((response)=>{
        setShippingorders([]);
        console.log(response.orders);
        if(response.auth)
        {
            return new Promise((resolve)=>{
                response.orders.forEach((order,index) => {
                    if(order.status==="PAIED")
                    {
                        setShippingorders((prevShippingorders)=>{return [...prevShippingorders,order];});
                        setShippingproducts((prevShippingproducts)=>{return [...prevShippingproducts,response.product[index]];});
                    }
                    else if(order.status==="IN TRANSIST")
                    {
                        setOnwayorders((prevShippingorders)=>{return [...prevShippingorders,order];});
                    }
                });
                resolve(true);
            });
        }
        else{
            alert("please login ");
            navigate("/login");
            
        }
    }).then((response)=>{
        setLoading(false);
    })
  },[]);


  const getuserorders = async ()=>{
    let response = await fetch("/api/apigetuserorders",{
        method:'POST',
        headers:{
            'Content-Type':'application/json',
            'X-CSRFToken': getcsrf(),
        },
    });
    let data = await response.json();
    return data;
  }
  return (
    <>
        {loading?
        <div className='w-screen h-screen flex items-center justify-center absolute z-50  gap-1  top-0 left-0' >
            <svg  className="icon fill-[#cccccc82]" strokeWidth={30} stroke='#ccc' xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 -960 960 960" width="30px" fill="#e8eaed"><path className='animate-lazyload' strokeDasharray={2400} strokeDashoffset={0} d="M440-183v-274L200-596v274l240 139Zm80 0 240-139v-274L520-457v274Zm-40-343 237-137-237-137-237 137 237 137ZM160-252q-19-11-29.5-29T120-321v-318q0-22 10.5-40t29.5-29l280-161q19-11 40-11t40 11l280 161q19 11 29.5 29t10.5 40v318q0 22-10.5 40T800-252L520-91q-19 11-40 11t-40-11L160-252Zm320-228Z"/></svg>
            <span className='font-normal text-[#ccc] text-[14px]'>Loading</span>
        </div>
        :
        <>
        <nav className='w-screen h-20 bg-white  px-10 flex items-center'>
            <span className='font-medium'>My Orders</span>
        </nav>
        <div className='w-screen h-fit min-h-[calc(100vh-80px)] bg-gray-50 p-20 max-[850px]:p-10 max-[550px]:p-5'>
            <div className='w-full h-[90px] relative'>
                <div className='w-full bg-gray-200 h-[1px] absolute bottom-0 rounded-full'>
                    <div className='w-[69px] h-full bg-black rounded-full'></div>
                </div>
                <div className='w-full h-full flex flex-col justify-between'>
                    <div className='w-fit h-fit'>
                        <span className='font-semibold text-[30px]'>Shipments</span>
                    </div>
                    <div className='w-fit h-fit flex gap-5'>
                        <div className='py-2 w-20'>
                           <span className='text-[12px] font-semibold'>UPCOMING</span> 
                        </div>
                        <div className='py-2 w-20'>
                            <span className='text-[12px] font-semibold text-[#ccc]'>HISTORY</span> 
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-col w-full h-auto'>
                    <div className='flex flex-col w-1/2 h-auto min-[1000px]:w-[600px] min-[800px]:w-[550px] min-[550px]:w-[500px] max-[550px]:w-full'>
                        <div className='w-full h-auto py-5'>
                            <span className='text-[20px] font-semibold text-black'>On the way</span>
                        </div>
                            <div className={`w-full transition-height duration-300 bg-white border border-gray-200 flex flex-col relative rounded-[10px] p-4 shadow-[rgba(100,100,111,0.15)_0px_7px_30px_0px] cursor-pointer`} style={{ height: isExpanded ? `${210 + (shippingorders.length * 120)}px` : "115px" }} onClick={(e)=>cardOpen(e)}>
                                <div className={`w-full flex gap-2 ${isExpanded?"h-[60px] gap-0":"h-[80px]"}`}>
                                    <div className={`transition-width duration-500  h-[80px] bg-gray-300 relative rounded ${isExpanded?"w-0 opacity-0":"w-[100px]"} overflow-hidden`}>
                                        {shippingorders.map((o,i)=>{
                                            if(i>=3){
                                                return;
                                            }
                                            return(
                                                <div 
                                                className="w-[60px] h-full bg-black rounded absolute shadow-[rgba(0,0,0,0.02)_0px_1px_3px_0px,rgba(27,31,35,0.15)_0px_0px_0px_1px border-l border-white rounded-md"
                                                style={{ left: `${i * 25}px`}}
                                                key={i}
                                                >
                                                        <img 
                                                            src={`https://firebasestorage.googleapis.com/v0/b/zades-c1877.appspot.com/o/${shippingproducts[i].productid+"_0"}?alt=media&token=3cbb4e36-63ac-4256-a8c3-0020016c0d89`} 
                                                            className="w-full h-full object-cover"
                                                        />
                                            </div>);
                                            
                                        })
                                        }
                                    </div>
                                    <div className='w-fit h-full flex flex-col'>
                                        <div className='w-fit h-auto flex flex-col justify-center'>
                                            <span className={`text-[12px] font-semibold text-black transition-all duration-500 ${isExpanded&&"text-[18px]"}`} >Estimated Delivery Date</span>
                                            <span className={`text-[10px] font-semibold text-[#0000008e] transition-all duration-500 ${!isExpanded&&"hidden"}`} >Total no.of items {shippingorders.length}</span>
                                        </div>
                                    </div>
                                    <div className='ml-auto'>
                                        <svg className={`transition-all duration-100 ${isExpanded&&"rotate-90"}`} xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#000"><path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z"/></svg>
                                    </div>
                                </div>
                                <div className={`w-full h-[1px] bg-gray-300 ${!isExpanded&&"hidden"}`}>

                                </div>
                                <div className={`w-full flex-auto flex flex-col ${!isExpanded&&"hidden"}`}>
                                    <div className={`w-full h-[1px] bg-gray-300 ${!isExpanded&&"hidden"}`}>

                                    </div>

                                    {shippingorders.map((item,index)=>{
                                        return(
                                        <div className='w-full h-[120px] py-[20px] flex gap-3 hover:bg-[#0000001b]'>
                                            <div className='h-full aspect-[3/4] bg-gray-300 rounded-[4px] overflow-hidden'>
                                                <img src={`https://firebasestorage.googleapis.com/v0/b/zades-c1877.appspot.com/o/${shippingproducts[index].productid+"_0"}?alt=media&token=3cbb4e36-63ac-4256-a8c3-0020016c0d89`} className="w-full h-full object-cover"/>
                                            </div>

                                            <div className='w-auto h-full flex flex-col gap-2 py-[2px]'>
                                                <span className='text-[10px] font-semibold'>{shippingproducts[index].name}</span>
                                                <div className='h-[20px] w-[20px] flex justify-center items-center border-[1px] border-[#ccc] rounded-[4px] shadow-[rgba(0,0,0,0.02)_0px_1px_3px_0px,rgba(27,31,35,0.15)_0px_0px_0px_1px'>
                                                    <span className='text-[8px] font-semibold text-[#787878]'>{item.size}</span>
                                                </div>
                                                <span className='mt-auto text-[10px] font-semibold text-[#787878]'>RS .{shippingproducts[index].price}</span>

                                            </div>
                                        </div>
                                        );
                                    })}


                                    
                                    <div className={`w-full h-auto py-2 px-3 mt-auto rounded-[6px] border border-gray-100 flex flex-col gap-2 shadow-[rgba(0,0,0,0.02)_0px_1px_3px_0px,rgba(27,31,35,0.15)_0px_0px_0px_1px] ${!isExpanded&&"hidden"}`}>
                                        <span className='text-[12px] font-semibold'>Shipping Address</span>
                                        <span className='text-[10px] font-semibold text-[#0000008c]'>RS .500</span>
                                    </div>

                                    <div className={`w-full h-10 px-3 mt-3 rounded-[6px] border border-gray-100 flex items-center shadow-[rgba(0,0,0,0.02)_0px_1px_3px_0px,rgba(27,31,35,0.15)_0px_0px_0px_1px] ${!isExpanded&&"hidden"}`}>
                                        <span className='text-[12px] font-semibold'>Total</span>
                                        <span className='text-[12px] font-semibold ml-auto'>RS .500</span>
                                    </div>
                                </div>
                            </div>
                    </div>

            </div>
        </div>
        <Footer />
        </>
        }
    </>
)
}

export default Orders