import React, { useEffect, useState } from "react";

const CardStatus = (props)=>{

    const status=["CART","ADDRESS","PAYMENT"];


    return(
        <>
        <div className='w-48 h-16 bg-[#eaeaea] rounded-full p-2 flex shadow-sm'>
            <div className='statussvg h-full aspect-square rounded-full bg-black flex justify-center items-center'>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className='shopsvg'><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path><line x1="3" y1="6" x2="21" y2="6"></line><path d="M16 10a4 4 0 0 1-8 0"></path></svg>
                <svg className='ticksvg animate-popup hidden' xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="18px" fill="#e8eaed"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
                <svg className='shopsvg hidden animate-popup' xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"  stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"><rect x="1" y="3" width="15" height="13"></rect><polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon><circle cx="5.5" cy="18.5" r="2.5"></circle><circle cx="18.5" cy="18.5" r="2.5"></circle></svg>
                <svg className='shopsvg hidden animate-popup'  xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"><rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect><line x1="1" y1="10" x2="23" y2="10"></line></svg>
            </div>
            <div className='flex-auto h-full flex flex-col gap justify-center p-3'>
            <span className='status text-xs font-semibold'>{status[props.status-1]}</span>
            <span className='text-[10px] font-semibold text-[#000000af]'>step {props.status}/3</span>
            </div>
            <div className='h-full aspect-square rounded-full flex justify-center items-center'>
            <svg width="40" height="40" viewBox="0 0 160 160" className='rotate-[-90deg] max-[400px]:w-10' >
                <circle r="70" cx="80" cy="80" fill="transparent" stroke="#00000040" stroke-width="12px" stroke-dasharray="439.6px" stroke-dashoffset="219.8px" strokeLinecap='round'></circle>
                <circle className='progress transition-all duration-1000 -z-10' r="70" cx="80" cy="80" fill="transparent" stroke="green" stroke-width="12px" stroke-dasharray="439.6px" stroke-dashoffset="366.36264000000006px" strokeLinecap='round'></circle>
            </svg>
            </div>
        </div>
      </>
    );
}

export default CardStatus;