import { useEffect, useState,useContext, useRef } from 'react';
import React from 'react';
import './App.css';
import CardStatus from './components/CartStatus.tsx';
import Navigation from './components/Navigation.tsx'
import CartCard from './components/CartCard.tsx';
import PaymentCard from './components/PaymentCard.tsx';
import Address from './components/Address.tsx';
import loadRazorpay from './components/Payment.tsx';
import apicarthandler from './cart.js';
import LazyLoader from './components/LazyLoader.tsx';
import { useDispatch,useSelector } from 'react-redux';
import Footer from './components/Footer.tsx';
import { login } from './microservices/AuthReducers.js';




export const CartContext = React.createContext();
export const AmountContext = React.createContext();

const CartPage = () => {

  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const userid=1;

  const [showlogin,setShowlogin] = useState(false);
  const [api,setApi] = useState(new apicarthandler(user,userid));
  const [statussvg,setStatussvg] = useState(1);
  const [progress,setProgress] = useState(false);
  const status=["CART","ADDRESS","PAYMENT"];
  const [cartitems,setCartitems] = useState([]);
  const [productitems,setProductitems] = useState([]);
  const [total,setTotal] = useState(0);
  const contentloaded = useRef(false);
  const addressloaded = useRef(false);
  const [address,setAddress] = useState([]);
  const [selected,setSelected] = useState(null);


  useEffect(()=>{
    calculatetotal();
  },[cartitems]);


  const calculatetotal = ()=>{
    setTotal(0);  
    cartitems.map((item,index)=>{
      let qty=item.unit;
      let price=parseInt(productitems[index].price);
      setTotal((prevTotal)=>{return prevTotal + (qty * price)})
    });
  }

  const loadcartitems = async ()=>{
    if(contentloaded.current) return;
    setCartitems([]);
    setProgress(true);
    api.getcartproducts().then((response)=>{
      console.log(response)
      setCartitems(response['cartobj']);
      setProductitems(response['productobj']);
      setProgress(false);
            
      contentloaded.current=true;
      if (response.auth)
      {
        dispatch(login({user:response.username}));
      }
      console.log(cartitems);
      console.log(productitems);
    });
  }



  useEffect(()=>{
    loadcartitems();
  },[]);


  const statusManager =()=>{
    setStatussvg(prevStatussvg => {
      let tempsvg=prevStatussvg+1;
      let svg=document.getElementsByClassName('shopsvg')[tempsvg-2];
      console.log(svg);
      let tick=document.getElementsByClassName('ticksvg')[0];
      let progress = document.getElementsByClassName("progress")[0];
      svg.classList.add("animate-popdown");
      progress.setAttribute("stroke-dashoffset",(439.6 * ((100 - (16.66 * tempsvg))/100)).toString()+"px");
      svg.classList.add("hidden");
      tick.classList.remove("hidden");
      tick.classList.add('flex');
      setTimeout(()=>{
        tick.classList.remove("flex");
        tick.classList.add("hidden");
        svg=document.getElementsByClassName('shopsvg')[tempsvg-1];
        svg.classList.remove("hidden");
        svg.classList.add("flex");
        console.log(svg);      
      },1000);
      return tempsvg;
  });
  }


  const statusrevManager = ()=>{
    setStatussvg(prevCount =>
    {
    if (prevCount===1){return prevCount}
    let tempcount=prevCount-1;
    let svg=document.getElementsByClassName('shopsvg')[tempcount];
    let tick=document.getElementsByClassName('ticksvg')[0];
    let progress = document.getElementsByClassName("progress")[0];
    svg.classList.add("animate-popdown");
    progress.setAttribute("stroke-dashoffset",(439.6 * ((100 - (16.66 * tempcount))/100)).toString()+"px");
    svg.classList.add("hidden");
    tick.classList.remove("hidden");
    tick.classList.add('flex');
    setTimeout(()=>{
      tick.classList.remove("flex");
      tick.classList.add("hidden");
      svg=document.getElementsByClassName('shopsvg')[tempcount-1];
      svg.classList.remove("hidden");
      svg.classList.add("flex");
      console.log(svg);      
    },1000);
    return tempcount;
  });
  }

  return (
    <>
      { showlogin &&
      <>
          <div className='w-screen h-screen fixed top-0 left-0 bg-[#00000037] z-50 flex justify-center items-center'  onClick={
            function(e){
              let innerdiv=document.getElementsByClassName("innerlogin")[0];
              if(!innerdiv.contains(e.target))
              {
                setShowlogin(false);
              } 
            }
            }>
            <div className='innerlogin w-auto h-auto bg-white flex flex-col p-3 rounded-[15px] gap-[1px]'>
              <div className='w-[300px] h-auto flex justify-between items-center'>
                  <span className='text-[20px] font-bold'>Log In</span>
                  <svg className='cursor-pointer' onClick={()=>{setShowlogin(false)}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
              </div>
              <span className='text-[12px] font-semibold text-[#00000089]'>Please log in to your account to continue..</span>
              <a href="/login" className='w-full h-[40px] bg-black rounded-[10px] flex items-center justify-center mt-[20px] cursor-pointer'><span className='text-white text-[12px] font-semibold'>LOGIN</span></a>
            </div>
        </div>  
      </>
      }

        <CartContext.Provider value={loadcartitems} >
         <div className='bg-white w-screen min-h-screen h-auto overflow-x-hidden'>
           <Navigation />
           <div className='cartwrapper w-screen min-h-[calc(100vh-120px)] h-auto p-5 max-[400px]:px-3'>
             <div className='flex h-auto w-full items-center justify-between'>
               <CardStatus status={statussvg} apifunc={api}/>
               <div className='h-10 aspect-square bg-white rounded-full flex items-center justify-center min-[400px]:hidden' onClick={()=>statusrevManager()}>
                 <svg xmlns="http:www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>
               </div>
             </div>
             <div className='cartcont w-full pt-5 h-[calc(100%-68px)] min-h-[200px] flex relative justify-between z-30'>
             {status[statussvg-1] === "CART"?
               <>
               <div className='cardcont w-[500px] flex flex-col gap-3 h-full min-h-[200px]  relative'>
                 {
                   progress && 
                   <>
                     <LazyLoader message={"loading products"} />
                   </>
                 }
                 {cartitems.map((cart,index)=>{
                   return(
                   <>
                     <CartCard  cartdata={cart} productdata={productitems[index]}  contentloaded={contentloaded}  calculatetotal={calculatetotal} />
                   </>
                   );
                 })}
                 </div>
                 <PaymentCard statusfunc={user?statusManager:()=>{setShowlogin(true);}} statusrevfunc={()=>alert("lol")} total={total} status={status[statussvg]} />
               </>:
               <>
                 <Address addressloaded={addressloaded} address={address} setAddress={setAddress} setSelected={setSelected} />
                 <PaymentCard statusfunc={()=>loadRazorpay(total*100,productitems,cartitems,address[selected])} statusrevfunc={statusrevManager} total={total} status={status[statussvg]} />
               </>
             }
             </div>
           </div>
         </div>
         <Footer />
       </CartContext.Provider>
    </>
  )
}

export default CartPage
