import React, { useState,useContext } from 'react'
import { useDispatch,useSelector } from 'react-redux';
import { login,logout } from './microservices/AuthReducers';
import { useNavigate } from 'react-router-dom';

const Login = () => {

  const [cardstate,setCardstate]  = useState('LOGIN');
  const navigate = useNavigate();
  const [username,setUsername] = useState('');
  const [password,setPassword] = useState('');
  const [rusername,setRusername] = useState('');
  const [rpassword,setRpassword] = useState('');
  const [repassword,setRepassword] = useState('');
  const [remail,setRemail] = useState('');
  const [validating,setValidating] = useState(false);
  const dispatch = useDispatch();


  const getcsrf = () => {
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
        const [name, value] = cookie.trim().split('=');
        if (name === 'csrftoken') {
            return decodeURIComponent(value);
        }
    }
    return null;
  };

  const validAndRegister =() =>{
    const validateEmail = (email) => {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    };

    if((rusername)&&(rpassword)&&(remail)&&(repassword))
    {
      if(validateEmail(remail))
      {
        if(rpassword===repassword)
        {
          return true;
        }
        else{
          alert(password)
          alert("password doesnt match");
          setValidating(false);
          return false;
        }
        
      }
      else{
        alert("invalid email");
        setValidating(false);
        return false;
      }
    }
    else{
      alert("enter all fields");
      setValidating(false);
      return false;
    }
  }

  const loginManager = async (e)=>{
        alert(document.cookie);
        try{
          let response = await fetch("/api/apilogin",{
              method:'POST',  
              headers:
              {
                  'Content-Type':'application/json',
                  'X-CSRFToken': getcsrf(),
              },
              credentials: 'include',
              body:JSON.stringify({
                  'username':username,
                  'password':password,
              })
          });

          let data= await response.json();
          console.log(data)
          if(response.status===200)
            {
              return new Promise((resolve)=>{
                dispatch(login({user:username}));
                setValidating(false);
                resolve(true);
              });
            }
            else{
              setValidating(false);
              alert("wrong username or password")
              return false;
            }
          }
      catch{

      }
}



  const registerManager = async ()=>{
    if(validAndRegister())
    {
      try{
        let response = await fetch("/api/apiregister",{
            method:'POST',  
            headers:
            {
                'Content-Type':'application/json',
                'X-CSRFToken': getcsrf(),
            },
            body:JSON.stringify({
                'username':rusername,
                'password':rpassword,
                'email':remail
            })
        });

        let data= await response.json();
        console.log(data)
        if(response.status===200)
          {
            return new Promise((resolve)=>{
              setValidating(false);
              resolve(true);
            });
          }
          else{
            setValidating(false);
            alert("wrong username or password")
            return false;
          }
        }
        
    catch{

    }
  }
  else{
    alert("error");
  }
  }

  return (
    <div className='w-screen h-screen bg-[rgb(255,255,255)] flex items-center justify-center'>
        <div className='w-[700px] h-auto bg-[#fff] p-5 rounded-[15px] flex border border-[#dfdede] shadow-sm relative overflow-hidden transition-height duration-150 ease-linear max-[740px]:w-[500px] flex-col max-[540px]:w-[90%] min-[740px]:flex'>
            <>
              {validating&&
                <>
                  <div className='w-full h-full bg-[#ffffff37] absolute top-0 left-0 z-40'>

                  </div>
                  <div className='w-1/5 h-1 bg-black absolute top-0 rounded animate-loginbar z-40'></div>
                </>
              }
            </>
            {
              cardstate==="LOGIN"?
              <>
              <div className='w-3/5 h-auto flex flex-col max-[740px]:w-full'>
                <h1 className='text-[20px] font-bold'>{cardstate}</h1>
                  <div className='flex flex-col gap-1 n mt-4'>
                      <span className='text-[8px] font-semibold'>USERNAME</span>
                      <input className='h-7 p-2 text-[10px] border border-[#dfdede] rounded-[4px] bg-[#f6f6f6]' type='text' placeholder='USERNAME' onChange={(e)=>{setUsername(e.target.value)}}/>
                  </div>
                  <div className='flex flex-col gap-1 n mt-2'>
                      <span className='text-[8px] font-semibold'>PASSWORD</span>
                      <input className='h-7 p-2 text-[10px] border border-[#dfdede] rounded-[4px] bg-[#f6f6f6]' type='password' placeholder='PASSWORD' onChange={(e)=>{setPassword(e.target.value)}}/>
                  </div>
                  <div className='flex w-[1/2] h-10 bg-black mt-4 items-center p-2 justify-between cursor-pointer rounded-sm' onClick={(e)=>{
                    setValidating(true);
                    loginManager(e).then((result)=>{
                    if(result)
                    {
                      navigate("/");
                    }
                  })}}>
                    <span className='text-[#fff] text-[12px] font-semibold'>{!validating?"LOGIN":"Loging in ...."}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="18px" fill="#e8eaed"><path d="m321-80-71-71 329-329-329-329 71-71 400 400L321-80Z"/></svg>
                </div>
              </div>
              <div className='min-[740px]:w-2/5 h-auto p-1 flex items-center justify-center gap-1 max-[740px]:w-fit mt-2'>
                <span className='text-[8px] text-[#0000009c]'>DONT HAVE AN ACCOUNT ?</span><span className='text-[8px] underline cursor-pointer' onClick={()=>{setCardstate("REGISTER")}}>REGISTER</span>
              </div>
              </>:
              <>
                <div className='w-3/5 h-auto flex flex-col max-[740px]:w-full'>
                  <h1 className='text-[20px] font-bold'>{cardstate}</h1>
                    <div className='flex flex-col gap-1 n mt-4'>
                        <span className='text-[8px] font-semibold'>USERNAME</span>
                        <input className='h-7 p-2 text-[10px] border border-[#dfdede] rounded-[4px] bg-[#f6f6f6]' type='text' placeholder='USERNAME' onChange={(e)=>{setRusername(e.target.value)}}/>
                    </div>
                    <div className='flex flex-col gap-1 n mt-4'>
                        <span className='text-[8px] font-semibold'>Email</span>
                        <input className='h-7 p-2 text-[10px] border border-[#dfdede] rounded-[4px] bg-[#f6f6f6]' type='email' placeholder='Email' onChange={(e)=>{setRemail(e.target.value)}}/>
                    </div>
                    <div className='flex flex-col gap-1 n mt-2'>
                        <span className='text-[8px] font-semibold'>PASSWORD</span>
                        <input className='h-7 p-2 text-[10px] border border-[#dfdede] rounded-[4px] bg-[#f6f6f6]' type='password' placeholder='PASSWORD' onChange={(e)=>{setRpassword(e.target.value)}}/>
                    </div>
                    <div className='flex flex-col gap-1 n mt-2'>
                        <span className='text-[8px] font-semibold'>RE-PASSWORD</span>
                        <input className='h-7 p-2 text-[10px] border border-[#dfdede] rounded-[4px] bg-[#f6f6f6]' type='password' placeholder='PASSWORD' onChange={(e)=>{setRepassword(e.target.value)}}/>
                    </div>
                    <div className='flex w-[1/2] h-10 bg-black mt-4 items-center p-2 justify-between cursor-pointer rounded-sm' onClick={(e)=>{
                    setValidating(true);
                    registerManager(e).then((result)=>{
                      if(result)
                      {
                        setCardstate("LOGIN");
                      }
                    }
                    )}}>
                      <span className='text-[#fff] text-[12px] font-semibold'>{!validating?"REGISTER":"Registering...."}</span>
                      <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="18px" fill="#e8eaed"><path d="m321-80-71-71 329-329-329-329 71-71 400 400L321-80Z"/></svg>
                    </div>
                </div>
                <div className='w-2/5 h-auto p-1 flex items-center justify-center gap-1 max-[740px]:w-fit mt-2'>
                  <span className='text-[8px] text-[#0000009c]'>ALREADY AN ACCOUNT ?</span><span className='text-[8px] underline cursor-pointer' onClick={()=>{setCardstate("LOGIN")}}>LOGIN</span>
                </div>
              </>
            }
        </div>
    </div>
  )
}

export default Login