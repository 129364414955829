import React from 'react'

const LazyLoader = (props) => {
  return (
    <div className='w-full h-full flex items-center justify-center absolute z-50  gap-1  top-0 left-0' >
        <svg  className="icon fill-[#cccccc82]" strokeWidth={30} stroke='#ccc' xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 -960 960 960" width="30px" fill="#e8eaed"><path className='animate-lazyload' strokeDasharray={2400} strokeDashoffset={0} d="M440-183v-274L200-596v274l240 139Zm80 0 240-139v-274L520-457v274Zm-40-343 237-137-237-137-237 137 237 137ZM160-252q-19-11-29.5-29T120-321v-318q0-22 10.5-40t29.5-29l280-161q19-11 40-11t40 11l280 161q19 11 29.5 29t10.5 40v318q0 22-10.5 40T800-252L520-91q-19 11-40 11t-40-11L160-252Zm320-228Z"/></svg>
        <span className='font-normal text-[#ccc] text-[14px]'>{props.message}</span>
    </div>
  )
}

export default LazyLoader