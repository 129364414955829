import React, { useState } from 'react';
import axios from 'axios';
import apicarthandler from '../cart';
import emailjs from 'emailjs-com'

const sendmailtoAdmin = (username,amount,product,cart,address) => {
  emailjs.send(
    'service_5nr2hfs', 
    'template_p3axk7f',
    {
      'from_name': username,
      'product_id':product.productid,
      'product_name':product.name,
      'units':cart.unit,
      'size':cart.size,
      'amount':amount,
      'pincode':address.pincode,
      'city':address.city,
      'name':address.name,
      'number':address.number,
      'address':address.address,
      'imagelink': "https://firebasestorage.googleapis.com/v0/b/zades-c1877.appspot.com/o/"+product.productid+"_1.jpg"+"?alt=media&token=3cbb4e36-63ac-4256-a8c3-0020016c0d89"
    },
    'ypGidfSNVm-zdy8zp', 

  ).then((response) => {
    console.log('SUCCESS!', response.status, response.text);
  }).catch((error) => {
    console.log('FAILED...', error);
    alert('Failed to send email. Please try again later.');
  });
};

// product_id,product_name,units,size,pincode,city,name,number,address,imagelink

const loadRazorpay = (am,productlist,cartlist,address) => {
  const script = document.createElement('script');
  script.src = 'https://checkout.razorpay.com/v1/checkout.js';
  script.onload = ()=>displayRazorpay(am,productlist,cartlist,address);
  document.body.appendChild(script);
};

const displayRazorpay = async (am,productlist,cartlist,address) => {


  const api=new apicarthandler();

  const getCSRFToken = () => {
    const csrfCookie = document.cookie.match(/csrftoken=([^ ;]+)/);
    return csrfCookie ? csrfCookie[1] : '';
  };
  
  try {
  //   const result = await axios.post('/api/createorder',JSON.stringify({
  //     amount: am,
  //     userid:api.userid,
  //   }),
  //   {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'X-CSRFToken': getCSRFToken(),  // Add CSRF token to headers
  //     },
  //     withCredentials: true,
  //   },
  // );
    const response = await fetch('/api/createorder', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': getCSRFToken(),  // Add CSRF token to headers
      },
      credentials: 'include',  // Include cookies for session authentication
      body: JSON.stringify({
        amount: am,
        userid: api.userid,
      }),
    });

    let result=await response.json();


    if (!result) {
      alert('Server error. Are you online?');
      return;
    }
    console.log(result);

    const options = {
      key: result.razorpay_merchant_key,
      amount: result.razorpay_amount.toString(),
      currency: result.currency,
      name: 'Zades',
      description: 'Test Transaction',
      order_id: result.razorpay_order_id,
      prefill: {
        name: api.username,
        email: '',
        contact: '',
      },
      handler:async function (response) {
        await fetch(result.callback_url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
            amount: am,
          }),
        }).then(async (response) =>productlist.forEach((product,index) => {
          sendmailtoAdmin(api.username,am,product,cartlist[index],address);          
        })).then(()=>window.location.href="/orders");
      },
      theme: {
        color: '#3399cc',
      },
      modal: {
        ondismiss: function () {
          console.log('Checkout form closed');
        }
      }
    };

    const paymentObject = new (window as any).Razorpay(options);
    paymentObject.open();
  } catch (error) {
    console.error('Error during payment:', error);
  }
};


export default loadRazorpay;